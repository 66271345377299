import { ReactNode, FC, memo, useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe as stripeLoader } from '@stripe/stripe-js/pure';
import { StripeConstructorOptions, StripeElementsOptions, Stripe } from '@stripe/stripe-js';
interface Props {
  children: ReactNode;
  apiKey?: string;
  locale?: string;
  options?: StripeElementsOptions;
}

export const StripeWrapper: FC<Props> = memo(({ children, locale, options, apiKey }: Props) => {
  const [stripe, setStripe] = useState<Stripe | null>();

  useEffect(() => {
    const handleLoad = async () => {
      const loadedScript = await stripeLoader(apiKey || process.env.NEXT_PUBLIC_STRIPE_API_KEY || '', {
        locale: locale as StripeConstructorOptions['locale'],
      });

      setStripe(loadedScript);
    };

    if (!stripe) {
      handleLoad();
    }
  }, [stripe, apiKey, locale]);

  return stripe ? (
    <Elements stripe={stripe} options={options}>
      {children}
    </Elements>
  ) : null;
});

StripeWrapper.displayName = 'StripeWrapper';
