export const constants: { [key: string]: { [key: string]: string } } = {
  en: {
    full_name: 'Full name',
    submit_secure_payment: 'Submit secure payment',
    or_pay_with_card: 'or pay with a card',
    credit_card: 'Credit Card',
    name_on_card: 'Name on card',
    card_number: 'Card number',
    expiration: 'Expiration',
    check_your_details: 'Please check your details and try again, or use another payment method',
    card_declined: 'Your card was declined',
    expired_card: 'The card has expired',
    expired_card_desc: 'Please check the expiration date or use a different card',
    incorrect_cvc: 'The card’s security code (CVC) is incorrect',
    incorrect_cvc_desc: 'Please check the card’s security code or use a different card',
    processing_error: 'An error occurred while processing the card',
    processing_error_desc: 'Please try again later or with a different payment method',
    incorrect_number: 'The card number is incorrect',
    incorrect_number_desc: 'Please check the card’s number or use a different card',
    fraudulent_desc: 'Try using a different card or payment method',
  },
  es: {
    full_name: 'Nombre y apellidos',
    submit_secure_payment: 'Enviar pago seguro',
    or_pay_with_card: 'o pagar con tarjeta',
    credit_card: 'Tarjeta de crédito',
    name_on_card: 'Nombre en la tarjeta',
    card_number: 'Número de tarjeta',
    expiration: 'Caducidad',
    check_your_details: 'Por favor, compruebe sus datos e inténtelo de nuevo, o utilice otro método de pago',
    card_declined: 'Su tarjeta ha sido rechazada',
    expired_card: 'La tarjeta ha caducado',
    expired_card_desc: 'Compruebe la fecha de caducidad o utilice otra tarjeta',
    incorrect_cvc: 'El código de seguridad de la tarjeta (CVC) es incorrecto',
    incorrect_cvc_desc: 'Compruebe el código de seguridad de la tarjeta o utilice otra tarjeta',
    processing_error: 'Se ha producido un error al procesar la tarjeta',
    processing_error_desc: 'Inténtelo de nuevo más tarde o con otro método de pago',
    incorrect_number: 'El número de la tarjeta es incorrecto',
    incorrect_number_desc: 'Compruebe el número de la tarjeta o utilice otra tarjeta',
    fraudulent_desc: 'Pruebe a utilizar otra tarjeta o método de pago',
  },
  de: {
    full_name: 'Vollständiger Name',
    submit_secure_payment: 'Sichere Zahlung einreichen',
    or_pay_with_card: 'oder mit einer Karte bezahlen',
    credit_card: 'Kreditkarte',
    name_on_card: 'Name auf der Karte',
    card_number: 'Kartennummer',
    expiration: 'Verfallsdatum',
    check_your_details:
      'Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut, oder verwenden Sie eine andere Zahlungsmethode',
    card_declined: 'Ihre Karte wurde abgelehnt',
    expired_card: 'Die Karte ist abgelaufen',
    expired_card_desc: 'Bitte überprüfen Sie das Ablaufdatum oder verwenden Sie eine andere Karte',
    incorrect_cvc: 'Der Sicherheitscode (CVC) der Karte ist falsch',
    incorrect_cvc_desc: 'Bitte überprüfen Sie den Sicherheitscode der Karte oder verwenden Sie eine andere Karte',
    processing_error: 'Bei der Verarbeitung der Karte ist ein Fehler aufgetreten',
    processing_error_desc: 'Bitte versuchen Sie es später oder mit einer anderen Zahlungsmethode erneut',
    incorrect_number: 'Die Kartennummer ist falsch',
    incorrect_number_desc: 'Bitte überprüfen Sie die Kartennummer oder verwenden Sie eine andere Karte',
    fraudulent_desc: 'Versuchen Sie, eine andere Karte oder Zahlungsmethode zu verwenden',
  },
  fr: {
    full_name: 'Nom complet',
    submit_secure_payment: 'Soumettre un paiement sécurisé',
    or_pay_with_card: 'ou payer par carte',
    credit_card: 'Carte de crédit',
    name_on_card: 'Nom sur la carte',
    card_number: 'Numéro de la carte',
    expiration: 'Expiration',
    check_your_details: 'Veuillez vérifier vos coordonnées et réessayer, ou utiliser un autre moyen de paiement.',
    card_declined: 'Votre carte a été refusée',
    expired_card: 'La carte a expiré',
    expired_card_desc: 'Veuillez vérifier la date d’expiration ou utiliser une autre carte',
    incorrect_cvc: 'Le code de sécurité de la carte (CVC) est incorrect',
    incorrect_cvc_desc: 'Veuillez vérifier le code de sécurité de la carte ou utiliser une autre carte',
    processing_error: 'Une erreur s’est produite lors du traitement de la carte',
    processing_error_desc: 'Veuillez réessayer plus tard ou avec un autre moyen de paiement.',
    incorrect_number: 'Le numéro de la carte est incorrect',
    incorrect_number_desc: 'Veuillez vérifier le numéro de la carte ou utiliser une autre carte',
    fraudulent_desc: "Essayez d'utiliser une autre carte ou un autre mode de paiement",
  },
  it: {
    full_name: 'Nome e cognome',
    submit_secure_payment: 'Invia un pagamento sicuro',
    or_pay_with_card: 'o pagare con una carta',
    credit_card: 'Carta di credito',
    name_on_card: 'Nome sulla scheda',
    card_number: 'Numero di carta',
    expiration: 'Scadenza',
    check_your_details: 'Controllare i dati e riprovare, oppure utilizzare un altro metodo di pagamento',
    card_declined: 'La carta è stata rifiutata',
    expired_card: 'La carta è scaduta',
    expired_card_desc: 'Si prega di controllare la data di scadenza o di utilizzare un’altra carta',
    incorrect_cvc: 'Il codice di sicurezza della carta (CVC) non è corretto',
    incorrect_cvc_desc: 'Controllare il codice di sicurezza della carta o utilizzare una carta diversa',
    processing_error: 'Si è verificato un errore durante l’elaborazione della carta',
    processing_error_desc: 'Riprovare più tardi o con un altro metodo di pagamento',
    incorrect_number: 'Il numero della carta non è corretto',
    incorrect_number_desc: 'Si prega di controllare il numero della carta o di utilizzare una carta diversa',
    fraudulent_desc: 'Provare a utilizzare una carta o un metodo di pagamento diverso',
  },
  pt: {
    full_name: 'Nome completo',
    submit_secure_payment: 'Enviar pagamento seguro',
    or_pay_with_card: 'ou pagar com cartão',
    credit_card: 'Cartão de crédito',
    name_on_card: 'Nome no cartão',
    card_number: 'Número do cartão',
    expiration: 'Prazo de validade',
    check_your_details: 'Verifique os seus dados e tente novamente, ou utilize outro método de pagamento',
    card_declined: 'O seu cartão foi recusado',
    expired_card: 'O cartão expirou',
    expired_card_desc: 'Verifique a data de validade ou utilize um cartão diferente',
    incorrect_cvc: 'O código de segurança do cartão (CVC) está incorreto',
    incorrect_cvc_desc: 'Verifique o código de segurança do cartão ou utilize um cartão diferente',
    processing_error: 'Ocorreu um erro ao processar o cartão',
    processing_error_desc: 'Tente novamente mais tarde ou com um método de pagamento diferente',
    incorrect_number: 'O número do cartão está incorreto',
    incorrect_number_desc: 'Verifique o número do cartão ou utilize um cartão diferente',
    fraudulent_desc: 'Tentar utilizar um cartão ou método de pagamento diferente',
  },
};

export const ERRORS = {
  generic: `Oopsie! Something went wrong with your payment. Please double-check your information and try again. If the issue persists, contact our support team. Thank you!`,
  missingPaymentMethod: `Oopsie! We couldn't process your payment with that card. Try another card or payment method. Thank you!`,
  loadingIssue: `Oopsie! Looks like we're facing issues with our current payment provider. Feel free to try another one for a smoother experience or refresh the page. If you need assistance or have any questions, reach out to our support team. Thanks for your understanding!`,
};

export const METHODS = {
  paypal: 'paypal',
};
